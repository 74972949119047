import { PureComponent } from "react";

import { Grid } from "@mui/material";

import { TelepresenceVideoContainer } from "src/domains/TelepresenceConsoleViewer/TelepresenceVideoContainer";

import { KinesisVideoStreamViewer } from "./KinesisVideoStreamViewer";
import styles from "./styles.scss";

interface TelepresenceConsoleViewerOwnProps {
  enableTelestration: boolean;
  enableConsoleTaps: boolean;
  videoProvider: KinesisVideoStreamViewer;
  meetingToken: any;
}
type TelepresenceConsoleViewerProps = TelepresenceConsoleViewerOwnProps;

// export const ConsoleViewerWithState = () => {
//   // Wrapper for redux store.
//   const videoProvider = new KinesisVideoStreamViewer();
//   const meetingToken = this.props.meetingToken;
//   return (
//     <Provider store={store}>
//       <TelepresenceConsoleViewer
//         enableTelestration={true}
//         enableConsoleTaps={true}
//         videoProvider={videoProvider}
//         meetingToken={this.props.meetingToken}
//       />
//     </Provider>
//   );
// };

export class TelepresenceConsoleViewer extends PureComponent<
  TelepresenceConsoleViewerProps
> {
  state = {
    showConsoleVideo: false,
    showJoinButton: false,
  };

  constructor(props: Readonly<TelepresenceConsoleViewerProps>) {
    super(props);
  }

  componentDidMount(): void {
    this.toggleShowConsoleVideoSession();
  }

  stopConsoleViewerSession = () => {
    this.props.videoProvider.stopConsoleViewerSession();
  };

  startConsoleViewerSession = () => {
    this.props.videoProvider.startConsoleViewerSession();
  };

  clearTelestrations = () => {};

  toggleShowConsoleVideoSession = () => {
    const curState = this.state.showConsoleVideo;

    this.setState({ showConsoleVideo: !this.state.showConsoleVideo });
    if (curState) {
      this.stopConsoleViewerSession();
    } else {
      this.startConsoleViewerSession();
    }
  };

  onChangeTextField = (newValue): void => {
    const showJoinButton = newValue && newValue !== "";
    this.setState({ showJoinButton });
    if (!showJoinButton) {
      this.setState({ showConsoleVideo: false });
    }
  };

  render() {
    const self = this;
    return (
      <form>
        <div className="kinesis-video-stream-form">
          <div className={styles.container}>
            {/* <div className={styles.header}>
              <h1 className={styles.title}>
                Mendaera Telepresence configuration
              </h1>
            </div> */}
            <div>
              <Grid direction="row" container spacing={1}>
                <br />
                {/* <Grid direction="column" container xs={100}>
                  <Item xs={100}>
                    <label>
                      Channel Name *: Enter the channel where the Cart's
                      TouchScreen is being streamed. You will then be able to
                      telestrate and send commands to the Cart.
                    </label>
                  </Item>
                </Grid>
                <br />
                <Grid direction="column" container xs={100}>
                  <Grid item xs={10} width={"20%"}>
                    <TextField
                      fullWidth={true}
                      data-test-id={DomainConstants.key.videoChannelName}
                      name={DomainConstants.key.videoChannelName}
                      label={DomainConstants.label.videoChannelName}
                      id={DomainConstants.key.videoChannelName}
                      placeholder={DomainConstants.placeholder.videoChannelName}
                      required
                      onChange={(e) => {
                        self.onChangeTextField(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    width={"80%"}
                    className={styles.joinConsoleSessionButton}
                  >
                    <Button
                      onClick={this.toggleShowConsoleVideoSession}
                      theme="primary"
                      data-test-id="event-join-button"
                      disabled={!this.state.showJoinButton}
                    >
                      {this.state.showConsoleVideo
                        ? "Leave Console Session"
                        : "Join Console Session"}
                    </Button>
                  </Grid>
                </Grid> */}
                {this.state.showConsoleVideo && (
                  <Grid direction="column" container xs={100}>
                    <Grid
                      item
                      xs={10}
                      width={"80%"}
                      className={styles.joinConsoleSessionButton}
                    />
                  </Grid>
                )}
                <Grid direction="column" container xs={100}>
                  {this.state.showConsoleVideo && (
                    <div className="row">
                      <div className="col">
                        <div className={styles.videoCanvasContainer}>
                          {/* <h5>
                          Streaming From Console. Hold the mouse and drag to
                          Telestrate. You can also tap anywhere on the video to
                          send a remote instruction.
                        </h5> */}
                          <TelepresenceVideoContainer />
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
