import { AWS_KINESIS_WEBRTC } from "src/constants";
import { ORTelligence } from "src/domains/Beacon/constants";
import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { createKinesisStreamsThunk } from "src/domains/Beacon/store/meeting/thunks/createKinesisStreamsThunk";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { resetConsoleToDefaultStateThunk } from "src/domains/Beacon/store/preset/thunks/resetConsoleToDefaultStateThunk";
import { preFetchIframeThunk } from "src/domains/Beacon/store/stream/thunks/preFetchIFrameThunk";
import { publishAudioTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishAudioTrackThunk";
import { publishLocalMediaTracksThunk } from "src/domains/Beacon/store/stream/thunks/publishLocalMediaTracksThunk";
import { publishVideoTrackThunk } from "src/domains/Beacon/store/stream/thunks/publishVideoTrackThunk";
import { twilioActions } from "src/domains/Beacon/store/twilio";
import {
  connectTwilioThunk,
  getCredentialsThunk,
} from "src/domains/Beacon/store/twilio/thunks";
import { uiActions } from "src/domains/Beacon/store/ui";
import { CallSteps } from "src/domains/Beacon/store/ui/types";
import { logger } from "src/logging/logger";

interface IParams {
  callDetails?: any;
  enableORTelligence: boolean;
  externalInputsImageResizing: boolean;
  offerDeviceSelection?: boolean;
}

export const joinCallThunk = ({
  callDetails,
  enableORTelligence,
  externalInputsImageResizing,
  offerDeviceSelection,
}: IParams) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (
      callDetails.video_channel_id &&
      callDetails.video_vendor === AWS_KINESIS_WEBRTC
    ) {
      await dispatch(createKinesisStreamsThunk({ callDetails }));
    } else {
      logger().info(
        "** Joining call, getting credentials and connecting to Twilio room **"
      );
      const { meeting } = getState();
      const { isUserHost, mode } = meeting;

      // Must get Twilio token before trying to join a room
      await dispatch(getCredentialsThunk());

      // TODO: This code will be removed later, currently we need to check if ORT iframe
      // is fetched based on the procedure and therapy names in the event, just to make sure if show/hide it
      // inside the Layouts SidePanel
      if (mode === CallModes.MP && enableORTelligence) {
        // Must await until TPI list is updated and ready to use
        logger().info("** Enabling TPI from joinCall **");
        await dispatch(preFetchIframeThunk({ integrationName: ORTelligence }));
      }

      logger().info("** Connecting to Twilio room **");
      await dispatch(connectTwilioThunk({ offerDeviceSelection }));

      // Only when Host joins the call, resets to default the
      // cameras, externalInputs, layouts, zoomState and TPI list
      if (mode === CallModes.MP && isUserHost) {
        // `didDiscoverVideoSources` or `didDiscoverCameras` will reset cameras in a P2P call

        // "await" isn't necessary here due that if Console hasn't joined, cameras will be empty and
        // an Error will be fired, thus, the join-call process will get stuck here
        dispatch(
          resetConsoleToDefaultStateThunk({ externalInputsImageResizing })
        );
      }
      // If Devices Selection is "true" we can publish a custom audio-input & camera devices
      // otherwise, we'll use default's System audio-input & camera devices
      if (offerDeviceSelection) {
        if (isUserHost) {
          logger().info(
            "** Publishing local video track since the user is host **"
          );
          await dispatch(publishVideoTrackThunk());
          dispatch(twilioActions.setHostHasJoinedRoom(true));
        }

        logger().info("** Publishing audio track **");
        await dispatch(publishAudioTrackThunk());
      } else {
        logger().info(
          "** pre-call modal is off, must publish local media tracks (audio & video) **"
        );
        await dispatch(publishLocalMediaTracksThunk());
      }
    }

    logger().info("** Setting the call step to IN_CALL **");
    // For now just move us into the call step (this doesn't call any API functions)
    dispatch(uiActions.setCallStep(CallSteps.IN_CALL));
  } catch (error: any) {
    logger().error("There was an error while joining the call", error?.message);
    throw error;
  }
};
