import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Can from '../../../../Common/Acl/AccessControl';
/* Actions */
import { logoutAction } from '../../../Authentication/AuthenticationActions';
/* Icons & Images */
import { newlogo2021 } from '../../../../Common/Assets/Images';
/* Components */
import ProfileImage from '../ProfileImage/ProfileImage';
/* Helpers */
import { getcurrentUserTypes } from '../../HomeSelectors';
import userTypes from '../../../../Common/Constants/userTypes';
import userService from '../../../../Common/Services/userService';
import { getUserOrganisationAction } from '../../Features/Users/UsersActions';
import { FEATURE_FLAGS } from "src/featureFlags";
import { isTrialUser } from "src/utils/user";
/* Styles */
import './Header.scss';
import NotificationBadge from '../NotificationBadge/NotificationBadge';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { sendCallbackAction } from '../../Features/Meeting/MeetingActions';
import {
  warningIcon
} from "../../../../Common/Assets/Images";
import { locations } from "src/utils/locations";

class Header extends PureComponent {
	state = {
		organisation: '',
		isCallbackExpired: false,
		lastMissedCallId: '',
	}

	componentDidMount() {
		const { currentUser } = this.props;

		if (currentUser.finishedAccountSetup && currentUser.userType !== 'GUEST') {
			this.props.getUserOrganisation(currentUser.id);
		}

		const timer = setInterval(this.watchOrgan, 500);
		// eslint-disable-next-line react/no-did-mount-set-state
		this.setState({ timer });
	}

	componentDidUpdate() {
		const { isCallbackExpired, lastMissedCallId } = this.state;
		const missed = this.getMostRecentMissedCall();

		if (missed && missed.id !== lastMissedCallId) {
			this.setState({ isCallbackExpired: false });
		}

		if (!isCallbackExpired && missed && missed.id !== lastMissedCallId) {
			const dateTime = moment(missed.start).tz(moment.tz.guess());
			const minuteDiff = moment(Date.now()).tz(moment.tz.guess()).diff(dateTime, 'minutes', true);
			const isLessThan15MinutesAgo = minuteDiff <= 15;

			if (isLessThan15MinutesAgo) {
				this.timeoutID = this.timerForMissedCall(missed); // cache the timeoutID
			}
		}

		if (isCallbackExpired) {
			clearTimeout(this.timeoutID);
		}
	}

	getOrganizationRoute = () => {
		const route = `/vendors/profile?brId=${this.props.userBrandId}`;
		return this.props.userOrganisation.vendorType === 'DISTRICT'
			? `${route}&buId=${this.props.userOrganisation.parentId}&dId=${this.props.userOrganisation.id}`
			: `${route}&buId=${this.props.userOrganisation.id}`;
	};

	redirect = (e, data) => this.props.history.push(data.value);

	watchOrgan = () => {
		if (this.state.organisation === '' && this.props.userOrganisation) {
			this.setState({
				organisation: this.props.userOrganisation.name,
			});
			clearInterval(this.state.timer);
		}
	}

	timerForMissedCall = (mostRecentMissed) => {
		const dateTime = moment(mostRecentMissed.start).tz(moment.tz.guess());
		const minuteDiff = moment(Date.now()).tz(moment.tz.guess()).diff(dateTime, 'minutes', true);
		const isLessThan15MinutesAgo = minuteDiff <= 15;

		if (isLessThan15MinutesAgo) {
			this.setState({ lastMissedCallId: mostRecentMissed.id });
			const timeout = 1000 * 60 * (15 - minuteDiff);
	
			return setTimeout(() => { // return the timeoutID
				this.setState({ isCallbackExpired: true });
			}, timeout);
		}

		return null;
	};

	getMostRecentMissedCall = () => {
		const { missedCalls } = this.props;

		// get the most recent missed call
		const mostRecentMissed = missedCalls[0];

		return mostRecentMissed || null;
	}

	render() {
		const { currentUser, logout, localNetworkDisrupted } = this.props;
		const { isCallbackExpired } = this.state;
		let showBadge = false;

		const mostRecentMissed = this.getMostRecentMissedCall();

		if (mostRecentMissed) {
			const dateTime = moment(mostRecentMissed.start).tz(moment.tz.guess());
			const minuteDiff = moment(Date.now()).tz(moment.tz.guess()).diff(dateTime, 'minutes');
			if (minuteDiff <= 15) {
				showBadge = true;
			}
		}

		return (
			<>
      {localNetworkDisrupted ? (
					<NotificationBanner 
						text={`Detected internet connectivity issues. Please restore the connection and click Reload to receive Incoming Calls.`}
						time={Date.now().toLocaleString}
						bgColor="blue" 
						action={() => {window.location.reload()}}
						actionText="Reload"
            icon={warningIcon}
					/>
				)
				: null
      }

			{!localNetworkDisrupted && showBadge && !isCallbackExpired && mostRecentMissed
				? (
					<NotificationBanner 
            text={`${mostRecentMissed.procedure.description} | ${mostRecentMissed?.room?.name} | ${mostRecentMissed.hospital.length && mostRecentMissed?.hospital[0]?.name}`}
						time={moment(mostRecentMissed.start).format('hh:mmA')}
						bgColor="blue" 
						action={() => this.props.sendCallback(mostRecentMissed)}
						actionText="Call Back"
					/>
				)
				: null
			}

			<div className="avail-header">
				<img className="avail-header-logo" src={newlogo2021} alt="Mendaera" />
				<a className="avail-header__right-wrapper">
					<Dropdown
						pointing="top right"
						text={`${currentUser.firstName} ${currentUser.lastName} 
						[${currentUser.userTypesFriendlyNames && currentUser.userTypesFriendlyNames[0]}, ${this.state.organisation}]`}
            data-test-id={'user-profile-dropdown'}
						icon={
							<React.Fragment>
								<ProfileImage
									size="md"
									initials={userService.getUserInitials(currentUser.firstName, currentUser.lastName)}
									className={`profile-image--${userTypes[currentUser.userTypes[0]].className} `}
								/>
								{showBadge && !isCallbackExpired ? <NotificationBadge color="red" floating size="mini" /> : null}
							</React.Fragment>
						}
						labeled
						className="avail-header__user-profile-dropdown"
					>
						<Dropdown.Menu>
							<Can userTypes={this.props.currentUserTypes} action="read" context="missedCalls">
							<Dropdown.Item
									className="avail-header__user-profile-dropdown-item"
									value={`/missed-calls`}
									onClick={this.redirect}
								>
									Missed Calls
								</Dropdown.Item>
							</Can>
							<Dropdown.Item
								className="avail-header__user-profile-dropdown-item"
								value={`/users/profile?userId=${currentUser.id}`}
								onClick={this.redirect}
							>
								My Profile
							</Dropdown.Item>
							{
								this.props.userOrganisation && (
									<Can
										userTypes={this.props.currentUser && this.props.currentUser.userTypes}
										action="read"
										context="myOrganization"
									>
										<Dropdown.Item
											className="avail-header__user-profile-dropdown-item"
											value={this.getOrganizationRoute()}
											onClick={this.redirect}
										>
											My Organization
										</Dropdown.Item>
									</Can>
								)
							}
              {isTrialUser(currentUser) && FEATURE_FLAGS.showUsagePage && (
                <Dropdown.Item
                  className="avail-header__user-profile-dropdown-item"
                  value={locations.usage}
                  onClick={this.redirect}
                  data-test-id="user-profile-dropdown-usage"
                >
                  Usage
                </Dropdown.Item>
              )}
							<Dropdown.Divider />
							<Dropdown.Item
								className="avail-header__user-profile-dropdown-item"
                data-test-id={"avail-logout"}
								onClick={logout}
							>
								Logout
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</a>
			</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	currentUserTypes: getcurrentUserTypes(state),
	currentUser: state.homeReducer.currentUser,
	userOrganisation: state.usersReducer.userOrganisation,
	meetingTime: state.schedulerReducer.meetingTime,
	missedCalls: state.schedulerReducer.missedCalls,
	userBrandId: state.usersReducer.userOrganisation && state.usersReducer.userOrganisation.brand && state.usersReducer.userOrganisation.brand.id,
  localNetworkDisrupted: state.homeReducer.localNetworkDisrupted
});

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logoutAction()),
	getUserOrganisation: id => dispatch(getUserOrganisationAction(id)),
	sendCallback: meeting => dispatch(sendCallbackAction(meeting)),
});

Header.propTypes = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
