import { MutableRefObject } from "react";

import pointerVector from "src/domains/Beacon/assets/pointer_vector.svg";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectTelestrationState } from "src/domains/Beacon/store/telestration/selectors";
import { getVideoDimensions } from "src/domains/Beacon/utils/canvas";

interface LaserPointerProps {
  canvasRef: MutableRefObject<HTMLCanvasElement>;
}

export const LaserPointer = ({ canvasRef }: LaserPointerProps) => {
  const { highlightPoints } = useAppSelector(selectTelestrationState);
  // get the last point in the array
  const highlightPoint = highlightPoints[highlightPoints.length - 1];

  if (!highlightPoint) {
    return null;
  }
  const boundingRect = canvasRef.current.getBoundingClientRect();
  const videoDimensions = getVideoDimensions();
  return (
    <img
      style={{
        marginLeft: -10 + "px",
        marginTop: -10 + "px",
        left:
          highlightPoint?.x * videoDimensions.width + boundingRect.left || 0,
        top: highlightPoint?.y * videoDimensions.height + boundingRect.top || 0,
        // width: 32 + "px",
        // height: 32 + "px",
        position: "absolute",
      }}
    />
  );
};
