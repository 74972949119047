import { useEffect, useState } from "react";

import clsx from "clsx";

import { AWS_KINESIS_WEBRTC } from "src/constants";
import { InputControlsMenu } from "src/domains/Beacon/components/InputControls/InputControlsMenu";
import { Kebab } from "src/domains/Beacon/components/InputControls/Kebab";
import { ORTelligence } from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { LayoutFrameNames } from "src/domains/Beacon/store/stream/types";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { FEATURE_FLAGS } from "src/featureFlags";

import styles from "./styles.scss";

export interface InputControlsProps {
  "data-test-id"?: string;
  isFullscreenOn: boolean;
  side: LayoutFrameNames;
  disabled: boolean;
  inputSourceId?: string;
}

export const InputControls = ({
  "data-test-id": dataTestId,
  isFullscreenOn = false,
  disabled = false,
  side,
  inputSourceId = "",
}: InputControlsProps) => {
  const { meetingToken } = useAppSelector(selectMeetingState);
  const mendaeraMeetingToken = meetingToken as any;
  let { consoleHasJoinedRoom } = useAppSelector(selectTwilioState);
  consoleHasJoinedRoom =
    mendaeraMeetingToken?.video_vendor === AWS_KINESIS_WEBRTC ||
    consoleHasJoinedRoom;

  const { enableORTelligence } = FEATURE_FLAGS;

  const shouldHideInputControls =
    !consoleHasJoinedRoom ||
    (enableORTelligence && inputSourceId === ORTelligence);
  const [openKebab, setOpenKebab] = useState<boolean>(false);

  const toggleKebab = () => setOpenKebab(!openKebab);

  // if this input control is disabled from the outside,
  // then also internally set the open state to false
  useEffect(() => {
    if (disabled) {
      setOpenKebab(false);
    }
  }, [disabled]);

  return (
    <div
      className={clsx(styles.root, {
        [styles.menu]: openKebab,
        [styles.disabled]: disabled,
        // if the console has not joined the room then hide this component
        [styles.hidden]: shouldHideInputControls,
      })}
      data-test-id={`${dataTestId}-menu-container`}
    >
      {openKebab && !disabled ? (
        <InputControlsMenu
          side={side}
          toggleKebab={toggleKebab}
          isFullscreenOn={isFullscreenOn}
          data-test-id={`${dataTestId}-menu`}
        />
      ) : (
        <Kebab toggleKebab={toggleKebab} disabled={disabled} />
      )}
    </div>
  );
};
