import { MNDHighlightLocationDragEvent } from "availkit-js/dist/Models/Events/MNDHighlightLocationDragEvent";
import { MNDHighlightLocationEndEvent } from "availkit-js/dist/Models/Events/MNDHighlightLocationEndEvent";
import { MNDHighlightLocationStartEvent } from "availkit-js/dist/Models/Events/MNDHighlightLocationStartEvent";
import { NHighlightLocationEvent } from "availkit-js/dist/Models/Events/NHighlightLocationEvent";
import { NAnnotationPoint } from "availkit-js/dist/Models/NAnnotationPoint";

import { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AWS_KINESIS_WEBRTC } from "src/constants";
import { INITIAL_PREVIOUS_POINT } from "src/domains/Beacon/components/Easel/constants";
import { TELESTRATION_EASEL_ID } from "src/domains/Beacon/components/Easel/constants";
import { IPosition, MAX_ANNOTATION_POINTS } from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectMeetingState,
  selectTriggerRefreshFrames,
} from "src/domains/Beacon/store/meeting/selectors";
import { selectIsUserHost } from "src/domains/Beacon/store/meeting/selectors";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { selectTelestrationState } from "src/domains/Beacon/store/telestration/selectors";
import { logger } from "src/logging/logger";
import { TwoDimensionPosition } from "src/services/ApiClient/presence/types";
import { AvailKitService } from "src/services/AvailKitService";

import { normalizeInstruction, draw } from "./helpers";

/**
 * Since these listeners need to be updated frequently during drawing, a hook is
 * preferable. Using thunks for each of these listeners has proven to be
 * resource heavy and results in reduced performance and accuracy in drawing.
 */

export const useMouseEventHandler = () => {
  const dispatch = useDispatch();
  const {
    penStyle: { color: penColor, width: penWidth },
    videoDimensions,
    isDrawModeOn,
    isLaserPointerModeOn,
    telestrationHistory,
  } = useAppSelector(selectTelestrationState);
  const isHostUser = useAppSelector(selectIsUserHost);
  const { callSid, meetingToken, mode } = useAppSelector(selectMeetingState);

  const mendaeraMeetingToken = meetingToken as any;
  const videoProvider = mendaeraMeetingToken?.video_vendor;

  const [_previousPoint, _setPreviousPoint] = useState<TwoDimensionPosition>(
    INITIAL_PREVIOUS_POINT
  );
  const previousPoint = useRef(_previousPoint);

  const setPreviousPoint = (data) => {
    previousPoint.current = data;
    _setPreviousPoint(data);
  };
  const availKit = AvailKitService.instance;
  const [_backingInstructions, _setBackingInstructions] = useState([]);
  const backingInstructions = useRef(_backingInstructions);
  const setBackingInstructions = (data: NAnnotationPoint[]) => {
    backingInstructions.current = data;
    _setBackingInstructions(data);
  };
  const refreshInProgress = useAppSelector(selectTriggerRefreshFrames);

  const canvas = document.getElementById(
    TELESTRATION_EASEL_ID
  ) as HTMLCanvasElement;
  const isAllowedToDraw =
    !refreshInProgress && canvas && isHostUser && isDrawModeOn;
  const isAllowedToLaserPoint =
    !refreshInProgress && canvas && isHostUser && isLaserPointerModeOn;

  const laserPointHelper = (event: MouseEvent, eventType: string) => {
    if (!canvas) {
      logger().info("No canvas - Cannot add Telestration mouseEvent listeners");
      return;
    }

    if (!isAllowedToLaserPoint) {
      logger().info("clicked...should not show laser pointer");
      return;
    } else {
      logger().info(
        "Remote Control : " + eventType + " ...should show laser pointer"
      );
    }

    let anInstruction = new NAnnotationPoint(
      event.pageX - videoDimensions.offsetWidth,
      event.pageY - videoDimensions.offsetHeight,
      penWidth
    );

    if (videoProvider === AWS_KINESIS_WEBRTC) {
      const boundingRect = canvas.getBoundingClientRect();
      anInstruction = new NAnnotationPoint(
        event.pageX - boundingRect.left,
        event.pageY - boundingRect.top,
        penWidth
      );
    }

    const normalizedPoint: NAnnotationPoint = normalizeInstruction(
      anInstruction,
      videoDimensions,
      penWidth
    );
    let aHighLightEventToPublish: any = null;
    switch (eventType) {
      case "START":
        aHighLightEventToPublish = new MNDHighlightLocationStartEvent(
          callSid,
          normalizedPoint.x,
          normalizedPoint.y
        );
        break;
      case "MOVE":
        aHighLightEventToPublish = new MNDHighlightLocationDragEvent(
          callSid,
          normalizedPoint.x,
          normalizedPoint.y
        );
        break;
      case "END":
        aHighLightEventToPublish = new MNDHighlightLocationEndEvent(
          callSid,
          normalizedPoint.x,
          normalizedPoint.y
        );
        break;
    }

    // Ideally, only one laser point should be active at a time
    dispatch(
      telestrationActions.setHighlightPoints([aHighLightEventToPublish])
    );
    if (availKit) {
      aHighLightEventToPublish.sender = "Send the actual login user id here";
      availKit.eventService.broadcast(aHighLightEventToPublish);
    }
  };

  // Obsolete. Not used anymore
  const onMouseClick = (event: MouseEvent) => {
    event.preventDefault();
    laserPointHelper(event, "END");
  };

  // onMouseDown event
  const onMouseDown = (event: MouseEvent) => {
    /**
     * the default behavior is to either drag and drop or highlight text.
     * preventDefault prevents bug in SOFT-7571 - Telestration Inoperable...
     * */
    event.preventDefault();
    if (!isAllowedToDraw) {
      /* If laser pointer mode is on, then we should delegate to laser pointer */
      if (isAllowedToLaserPoint) {
        laserPointHelper(event, "START");
      } else {
        return;
      }
    }

    canvas.addEventListener("mousemove", onMouseMove);
    const boundingRect = canvas.getBoundingClientRect();

    let newPreviousPoint: any = {
      x: event.pageX - videoDimensions.offsetWidth,
      y: event.pageY - videoDimensions.offsetHeight,
    };

    if (videoProvider === AWS_KINESIS_WEBRTC) {
      newPreviousPoint = {
        x: event.pageX - boundingRect.left,
        y: event.pageY - boundingRect.top,
      };
    }
    setPreviousPoint(newPreviousPoint);
    buildAnnotationPointHelper(newPreviousPoint);
    dispatch(telestrationActions.setSendEvent({ type: "START" }));
  };

  const drawLocalAnnotation = (point: IPosition) => {
    const styles = {
      width: penWidth,
      color: penColor,
    };
    draw(previousPoint.current, point, styles);

    const newPreviousPoint = { x: point.x, y: point.y };
    setPreviousPoint(newPreviousPoint);
  };

  const buildAnnotationPointHelper = (anInstruction: any): void => {
    const aPoint: NAnnotationPoint = normalizeInstruction(
      anInstruction,
      videoDimensions,
      penWidth
    );
    const backingInstructionsCopy: NAnnotationPoint[] =
      backingInstructions.current;
    setBackingInstructions([...backingInstructionsCopy, aPoint]);
  };

  const boundingRect = canvas?.getBoundingClientRect();
  const helperForTouchOrMouseEvent = (aTouch: any) => {
    let anInstruction = {
      x: aTouch.pageX - videoDimensions.offsetWidth,
      y: aTouch.pageY - videoDimensions.offsetHeight,
    };
    if (videoProvider === AWS_KINESIS_WEBRTC) {
      anInstruction = {
        x: aTouch.pageX - boundingRect.left,
        y: aTouch.pageY - boundingRect.top,
      };
    }

    if (isAllowedToDraw) {
      drawLocalAnnotation(anInstruction);
    }

    if (aTouch.pageX && aTouch.pageY) {
      buildAnnotationPointHelper(anInstruction);
    }

    const backingInstructionsCopy = [
      ...backingInstructions.current,
    ] as NAnnotationPoint[];
    if (backingInstructionsCopy.length === MAX_ANNOTATION_POINTS) {
      const points: NAnnotationPoint[] = backingInstructionsCopy.splice(
        0,
        MAX_ANNOTATION_POINTS
      );
      if (isAllowedToDraw) {
        // TODO : If it is remote control event, send MND*Event instead of telestration event
        dispatch(
          telestrationActions.setSendEvent({
            type: "ANNOTATION",
            payload: points,
          })
        );
      } else if (isAllowedToLaserPoint) {
        laserPointHelper(aTouch, "MOVE");
      }

      /* Retain the last point */
      setBackingInstructions([points[MAX_ANNOTATION_POINTS - 1]]);
    }
  };

  // onMouseMove event
  const onMouseMove = (moveEvent: MouseEvent): void => {
    const anEvent: React.MouseEvent<
      HTMLCanvasElement,
      MouseEvent
    > = (moveEvent as unknown) as React.MouseEvent<
      HTMLCanvasElement,
      MouseEvent
    >;
    if (!isAllowedToDraw) {
      // only the Host get to draw
      /* If laser pointer mode is on, then we should delegate to laser pointer */
      if (isAllowedToLaserPoint) {
        // laserPointHelper(moveEvent, "MOVE");
      } else {
        return;
      }
    }

    /* Always make sure left button is down while moving */
    if (anEvent.buttons === 1) {
      helperForTouchOrMouseEvent(anEvent);
    } else {
      onMouseUp(moveEvent);
    }
  };

  const endLine = (event) => {
    if (!isAllowedToDraw) {
      /* If laser pointer mode is on, then we should delegate to laser pointer */
      if (isAllowedToLaserPoint) {
        laserPointHelper(event, "END");
      } else {
        // only the Host get to draw
        return;
      }
    }
    canvas.removeEventListener("mousemove", onMouseMove);

    setPreviousPoint(INITIAL_PREVIOUS_POINT);
    if (backingInstructions.current.length > 0) {
      dispatch(
        telestrationActions.setSendEvent({
          type: "ANNOTATION",
          payload: backingInstructions.current,
        })
      );
      setBackingInstructions([]);
    }
    dispatch(telestrationActions.setSendEvent({ type: "END" }));
  };

  // onMouseUp event
  const onMouseUp = (event: MouseEvent): void => {
    endLine(event);
  };

  const onMouseLeave = (event) => {
    // only end line and send endEvent if drawing
    if (event.buttons === 1) {
      endLine(event);
    }
  };

  // add onMouseEvent listeners
  useEffect(() => {
    if (!canvas || !(isDrawModeOn || isLaserPointerModeOn)) {
      if (!canvas) {
        logger().info(
          "No canvas - Cannot add Telestration mouseEvent listeners"
        );
      }
      return;
    }

    if (isDrawModeOn || isLaserPointerModeOn) {
      logger().info(`Updating Telestration onMouseEvent listeners`);
      canvas.addEventListener("mousedown", onMouseDown);
      canvas.addEventListener("mouseup", onMouseUp);
      canvas.addEventListener("mouseleave", onMouseLeave);
      // } else if (isLaserPointerModeOn) {
      //   canvas.addEventListener("click", onMouseClick);
    }

    return () => {
      canvas.removeEventListener("mousedown", onMouseDown);
      canvas.removeEventListener("mouseup", onMouseUp);
      canvas.removeEventListener("mouseleave", onMouseLeave);
      canvas.removeEventListener("click", onMouseClick);
    };
  }, [
    canvas,
    isDrawModeOn,
    penColor,
    videoDimensions,
    penWidth,
    telestrationHistory,
    isAllowedToDraw,
    isAllowedToLaserPoint,
  ]);
};
