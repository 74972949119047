import { useRef } from "react";

import clsx from "clsx";

import { AWS_KINESIS_WEBRTC } from "src/constants";
import { ConsoleNotPresent } from "src/domains/Beacon/components/Console/ConsoleNotPresent";
import { ConsoleVideoMuted } from "src/domains/Beacon/components/Console/ConsoleVideoMuted";
import { Easel } from "src/domains/Beacon/components/Easel/Easel";
import { LaserPointer } from "src/domains/Beacon/components/Easel/LaserPointer/LaserPointer";
import { FreezeFrameCanvas } from "src/domains/Beacon/components/FreezeFrameCanvas";
import { Integration } from "src/domains/Beacon/components/Integration";
import { LayoutsBorder } from "src/domains/Beacon/components/LayoutsBorder";
import { Video } from "src/domains/Beacon/components/Video";
import { CONSOLE_VIDEO_ELEMENT_ID } from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import {
  selectIsIntegrationActive,
  selectStreamState,
} from "src/domains/Beacon/store/stream/selectors";
import { selectTelestrationState } from "src/domains/Beacon/store/telestration/selectors";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { TelepresenceConsoleViewer } from "src/domains/TelepresenceConsoleViewer";
import { KinesisVideoStreamViewer } from "src/domains/TelepresenceConsoleViewer/KinesisVideoStreamViewer";

import styles from "./styles.scss";

interface IProps {
  showConsoleNotPresent: boolean;
}

export const Console = ({ showConsoleNotPresent }: IProps) => {
  // TODO: update these state values when console hangs up or is disconnected
  const { mpRemoteTracks, console: consoleState, layoutType } = useAppSelector(
    selectStreamState
  );
  const { consoleHasJoinedRoom, localNetworkDisconnected } = useAppSelector(
    selectTwilioState
  );

  const isIntegrationActive = useAppSelector(selectIsIntegrationActive);

  const videoTrack = mpRemoteTracks.console.video;
  // Console's Video stream ref
  const videoRef = useRef<HTMLVideoElement>();
  const canvasRef = useRef<HTMLCanvasElement>();

  const isConsoleVideoMuted =
    consoleHasJoinedRoom && consoleState.video === "mute";
  const showVideo = !isConsoleVideoMuted && consoleHasJoinedRoom;

  const { meetingToken, mode } = useAppSelector(selectMeetingState);

  const mendaeraMeetingToken = meetingToken as any;
  const videoProvider = mendaeraMeetingToken?.video_vendor;

  const { highlightPoints } = useAppSelector(selectTelestrationState);
  console.log(`highlightPoints: ${highlightPoints}`);
  return (
    <div className={clsx(styles.root)}>
      {/* <ConsoleNotPresent
        // show loader while waiting for the Console video track to start
        open={
          !localNetworkDisconnected &&
          !(consoleHasJoinedRoom && videoTrack?.isStarted) &&
          showConsoleNotPresent
        }
        message={"Please wait for the procedure room to join the event"}
      /> */}
      {/* <ConsoleVideoMuted open={isConsoleVideoMuted} layoutType={layoutType} /> */}
      <div className={clsx(styles.videoContainer)} id="console-video-container">
        {isIntegrationActive && <Integration />}
        <FreezeFrameCanvas />
        {/* if isIntegrationActive is NOT active then we want to use the side panel offset,
            that offset refers to the width of the side panel since it will push the video content
            if the isIntegrationActive is active (true) then we dont want to use the offset (we send false to useSidePanelOffset)
        */}
        <LayoutsBorder useSidePanelOffset={!isIntegrationActive} />
        {videoProvider === AWS_KINESIS_WEBRTC && (
          <TelepresenceConsoleViewer
            enableTelestration={true}
            enableConsoleTaps={true}
            meetingToken={meetingToken}
            videoProvider={new KinesisVideoStreamViewer(meetingToken)}
          />
        )}
        {videoProvider !== AWS_KINESIS_WEBRTC && (
          <Video
            track={videoTrack}
            id={CONSOLE_VIDEO_ELEMENT_ID}
            data-test-id={CONSOLE_VIDEO_ELEMENT_ID}
            videoRef={videoRef}
            consoleHasJoinedRoom={consoleHasJoinedRoom}
            showVideo={showVideo}
          />
        )}
      </div>
      <Easel canvasRef={canvasRef} />
      {highlightPoints.length > 0 && <LaserPointer canvasRef={canvasRef} />}
    </div>
  );
};
