import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/reducer";
import { logger } from "portalcall/commoncall/common/logger";
import { endCall } from "portalcall/commoncall/store/user/actions";

import "./styles.scss";
import { NTelephonyService } from "availkit-js/dist/Services/NTelephonyService";
import { AVKLastParticipantEvent } from "availkit-js/dist/Models/Events/AVKLastParticipantEvent";
import { NEvent } from "availkit-js";

interface TelephonyServiceCallbacks {
  [method: string]: (service: NTelephonyService, event: NEvent) => void
}

// TODO replace NEvent with actual events 
// These are all methods that the telephony listeners should implement
const availKitStubs = {
  onReceiveIncomingCall: (service: NTelephonyService, event: NEvent) => {},
  onReceiveIncomingServerCall: (service: NTelephonyService, event: NEvent) => {},
  onReceiveMendaeraIncomingServerCall: (service: NTelephonyService, event: NEvent) => {},
  onReceiveHangupV2: (service: NTelephonyService, event: NEvent) => {},
  onReceiveUserPreset: (service: NTelephonyService, event: NEvent) => {},
  onReceiveVideoSourcePresetLayout: (service: any, event: any) => {},
  onReceiveCameraChange: (service: NTelephonyService, event: NEvent) => {},
  onReceiveCameraConfig: (service: NTelephonyService, event: NEvent) => {},
  onReceiveCameraMove: (service: NTelephonyService, event: NEvent) => {},
  onReceiveCameraZoom: (service: NTelephonyService, event: NEvent) => {},
  onReceiveCameraReset: (service: NTelephonyService, event: NEvent) => {},
  onReceivePreset: (service: NTelephonyService, event: NEvent) => {},
  onReceiveConsoleCallbackIncoming: (service: NTelephonyService, event: NEvent) => {},
  onReceiveConsoleCallbackAcceptEvent: (service: NTelephonyService, event: NEvent) => {},
  onReceiveConsoleCallbackDeclineEvent: (service: NTelephonyService, event: NEvent) => {},
  onReceiveLastParticipantEvent: (service: NTelephonyService, event: NEvent) => {},
  onReceiveVideoLayoutChange: (service: any, event: any) => {},
  didJoinSession: (service: NTelephonyService, event: NEvent) => {},
}

const DEFAULT_END_CALL_TIMEOUT = 120; // seconds

// Stub all the callbacks that we don't use
function createTelephonyListener (callbacks: TelephonyServiceCallbacks) {
  return { ...availKitStubs, ...callbacks };
}

interface Props {
  onCleanup: () => void;
}

export function LastParticipantModal(props: Props) {
  const dispatch = useDispatch();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  const availKit = useSelector((state: AppState) => state.availKit.availKitInstance);

  // cleanup timeout on unmount
  useEffect(() => () => clearTimeout(timeoutRef.current), []);
  
  useEffect(() => {
    // This should run up until Availkit is instatiated and then never again
    if (!availKit) return;
    
    const telephonyListener = createTelephonyListener({ onReceiveLastParticipantEvent });
    availKit?.telephonyService.addEventListener(telephonyListener);    
    return () => availKit?.telephonyService.removeEventListener(telephonyListener);
  }, [availKit]);

  const onReceiveLastParticipantEvent = (
    service: NTelephonyService, 
    event: AVKLastParticipantEvent
  ) => {
    logger().info("Received a last participant (end call) event v2.", event);
    setIsOpen(true);
  
    // In case a second message is received, clear timeout before replacing ref
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      // Give user some time to respond then end the call after specified time
      logger().info("User is last participant in event and has not responded to prompts. Disconnecting user from call ...");
      onLeave();
    }, (event.popup_duration || DEFAULT_END_CALL_TIMEOUT) * 1000);
  }

  const onLeave = () => {
    logger().info("Attempting to disconnect");
    // This logic is necessary to take from Video call as we need to remove 
    // event listener for onunload
    props.onCleanup();
    dispatch(endCall());
    setIsOpen(false);
  }

  const onStay = () => {
    logger().info("With end time exceeded, participants is choosing to stay in call.");
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(false);
  }

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="avail-portal-call-overlay">
        <div className="last-participant-overlay-dialog">
          <div className="last-participant-dialog-title">
            Event ends in 2 mins. <br />
            Do you want to end the event now?
          </div>
          <div className="last-participant-button-holder">
            <button className="confirm-leave-call-button" onClick={onLeave}>
              Leave
            </button>
            <button className="confirm-stay-in-call-button" onClick={onStay}>
              Stay
            </button>
          </div>
        </div>
      </div>,
    document.getElementById("root") // For now, append modals to top level
  );
}
