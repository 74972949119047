// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root_tiEA6S{position:absolute;z-index:96}", "",{"version":3,"sources":["webpack://./src/domains/Beacon/components/Easel/styles.scss"],"names":[],"mappings":"AAEA,oBAIE,iBAAA,CACA,UAAA","sourcesContent":["@import \"src/global.scss\";\n\n.root {\n  // background-color: #00f;\n  // opacity: 0.2;\n  // cursor: url(\"../../../../../avail-web-ui/src/assets/images/target.png\") 20 20, pointer;\n  position: absolute;\n  z-index: map-get($map: $beacon-zindex, $key: easel);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root_tiEA6S"
};
export default ___CSS_LOADER_EXPORT___;
