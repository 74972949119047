import { AppDispatch } from "src/domains/Beacon/store";
import { logger } from "src/logging/logger";

interface IParams {
  callDetails: any;
}

export const createKinesisStreamsThunk = ({ callDetails }: IParams) => async (
  dispatch: AppDispatch
  // eslint-disable-next-line require-await
) => {
  try {
    logger().info(
      "** Creating Kinesis streams **" + JSON.stringify(callDetails)
    );
  } catch (error: any) {
    logger().error(
      "Error while extracting meeting token. useGetMeetingToken",
      error?.message
    );
  }
};
