import { useState } from "react";
import { useDispatch } from "react-redux";

import clsx from "clsx";

import { RecordVoiceOverOutlined } from "@mui/icons-material";

import { Button } from "src/components/Button";
import { Popover } from "src/components/Popover/Popover";
import { AWS_KINESIS_WEBRTC } from "src/constants";
import { BeaconControlsToolTips } from "src/constants/tooltips";
import {
  ACTIVATE_SIDEBAR_MESSAGE,
  ACTIVATE_SIDEBAR_TITLE,
  DEACTIVATE_SIDEBAR_MESSAGE,
  DEACTIVATE_SIDEBAR_TITLE,
} from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import {
  selectIsSidebarLoading,
  selectSidebarState,
} from "src/domains/Beacon/store/stream/selectors";
import { toggleSidebarStatusThunk } from "src/domains/Beacon/store/stream/thunks/toggleSidebarStatusThunk";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";

import styles from "./styles.scss";

export const SidebarButton = () => {
  // Controls the Sidebar Popover open/hide state
  const [open, setOpen] = useState<boolean>(false);
  const { meetingToken } = useAppSelector(selectMeetingState);
  const mendaeraMeetingToken = meetingToken as any;
  let { consoleHasJoinedRoom } = useAppSelector(selectTwilioState);
  consoleHasJoinedRoom =
    mendaeraMeetingToken?.video_vendor === AWS_KINESIS_WEBRTC ||
    consoleHasJoinedRoom;

  const sidebarStatus = useAppSelector(selectSidebarState);
  const isSidebarLoading = useAppSelector(selectIsSidebarLoading);
  const dispatch = useDispatch();

  const handleToggleSidebar = () => {
    setOpen(false);
    dispatch(toggleSidebarStatusThunk());
  };

  // Sidebar state could be active but we don't show it in the UI until
  // Console has joined
  const isSidebarActive = sidebarStatus === "active" && consoleHasJoinedRoom;

  return (
    <Popover
      key="sidebar-popover"
      open={open}
      onClose={() => setOpen(false)}
      showArrow={false}
      contentProps={{
        title:
          sidebarStatus === "active"
            ? DEACTIVATE_SIDEBAR_TITLE
            : ACTIVATE_SIDEBAR_TITLE,
        description:
          sidebarStatus === "active"
            ? DEACTIVATE_SIDEBAR_MESSAGE
            : ACTIVATE_SIDEBAR_MESSAGE,
        footer: (
          <div className={styles.footerPopover}>
            <Button
              className={styles.button}
              data-test-id="sidebar-button-activate"
              label={sidebarStatus === "active" ? "Turn Off" : "Turn On"}
              onClick={handleToggleSidebar}
              disabled={isSidebarLoading}
            />
            <Button
              className={clsx(styles.button, styles.cancel)}
              data-test-id="sidebar-button-cancel"
              label="Cancel"
              onClick={() => setOpen(false)}
            />
          </div>
        ),
      }}
      data-test-id="sidebar-popover"
      popoverPosition="top"
      pointingPosition="left"
      children={
        <Button
          variant="icon"
          endIcon={<RecordVoiceOverOutlined />}
          disabled={!consoleHasJoinedRoom || isSidebarLoading}
          tooltipProps={{
            title: BeaconControlsToolTips.sideBarButton,
            placement: "top",
          }}
          data-test-id="sidebar-button"
          onClick={() => setOpen(true)}
          active={isSidebarActive}
        />
      }
    />
  );
};
