import { useDispatch } from "react-redux";

import clsx from "clsx";

import {
  Edit,
  PanToolAltOutlined as Laser,
  MicNone,
  MicOff,
  VideocamOutlined as VideoCam,
  VideocamOffOutlined as VideoCamOff,
  CallEnd,
} from "@mui/icons-material";

import { Button } from "src/components/Button";
import { NavBar } from "src/components/NavBar";
import { AWS_KINESIS_WEBRTC } from "src/constants";
import { BeaconControlsToolTips } from "src/constants/tooltips";
import { SidebarButton } from "src/domains/Beacon/components/BottomBar/SidebarButton";
import { TELESTRATION_EASEL_ID } from "src/domains/Beacon/components/Easel/constants";
import {
  CONSOLE_VIDEO_ELEMENT_ID,
  DRAWER_WIDTH,
} from "src/domains/Beacon/constants";
import { useAppSelector } from "src/domains/Beacon/store";
import {
  selectCallMeetingModeState,
  selectIsUserHost,
  selectMeetingState,
} from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { selectStreamState } from "src/domains/Beacon/store/stream/selectors";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { selectTelestrationState } from "src/domains/Beacon/store/telestration/selectors";
import { handleTurnOffDrawModeThunk } from "src/domains/Beacon/store/telestration/thunks/handleTurnOffDrawModeThunk";
import { handleTurnOffLaserPointerModeThunk } from "src/domains/Beacon/store/telestration/thunks/handleTurnOffLaserPointerModeThunk";
import {
  selectLocalParticipant,
  selectTwilioState,
} from "src/domains/Beacon/store/twilio/selectors";
import { uiActions } from "src/domains/Beacon/store/ui";
import { selectNoConnectionModalOpen } from "src/domains/Beacon/store/ui/selectors";
import {
  selectMicrophoneMuted,
  selectShowPipVideo,
} from "src/domains/Beacon/store/ui/selectors";
import {
  getConsoleVideoElement,
  getTelestrationCanvas,
  getVideoDimensions,
} from "src/domains/Beacon/utils/canvas";
import {
  disableAudioTrack,
  disableVideoTrack,
  enableAudioTrack,
  enableVideoTrack,
} from "src/domains/Beacon/utils/twilio";
import { useScssVariable } from "src/hooks/useScssVariable";
import { logger } from "src/logging/logger";

import styles from "./styles.scss";

export interface IProps {
  sidePanelOpen: boolean;
  hidden: boolean;
  isHost: boolean;
}

export const BottomBar = ({ sidePanelOpen, hidden, isHost }: IProps) => {
  // Passes the drawer width to a Scss var, './styles.scss'
  const { ref } = useScssVariable<HTMLDivElement>(
    "--drawer-width",
    `${DRAWER_WIDTH}px`
  );

  const { meetingToken } = useAppSelector(selectMeetingState);
  const mendaeraMeetingToken = meetingToken as any;
  let { consoleHasJoinedRoom } = useAppSelector(selectTwilioState);
  consoleHasJoinedRoom =
    mendaeraMeetingToken?.video_vendor === AWS_KINESIS_WEBRTC ||
    consoleHasJoinedRoom;

  const isVideoShown = useAppSelector(selectShowPipVideo);
  const isHostUser = useAppSelector(selectIsUserHost);
  const isMicrophoneMuted = useAppSelector(selectMicrophoneMuted);
  const localParticipant = useAppSelector(selectLocalParticipant);
  const { isDrawModeOn, isLaserPointerModeOn } = useAppSelector(
    selectTelestrationState
  );
  const { console, thirdPartyIntegrationAvailable } = useAppSelector(
    selectStreamState
  );
  const callMode = useAppSelector(selectCallMeetingModeState);
  // Must wait until video/audio is published to Twilio
  const { isVideoTrackPublished, isAudioTrackPublished } = useAppSelector(
    selectStreamState
  );
  const noConnectionModalOpen = useAppSelector(selectNoConnectionModalOpen);

  // Some features shouldn't be used in non-MP calls
  const isMPCallMode = callMode === CallModes.MP;

  // disable video mute when there is no track to mute or thirdPartyIntegrationAvailable available
  // muted video when screen sharing also mutes the screen share
  const disableVideoMute =
    !isVideoTrackPublished || thirdPartyIntegrationAvailable;

  const dispatch = useDispatch();

  // consoleVideo-related conditions that require a group of buttons to be disabled
  const isConsoleVideoMuted =
    !consoleHasJoinedRoom || console.video === "mute" || noConnectionModalOpen;

  // Checks if the Pip's video is playing, then it'll be stopped, otherwise,
  // it will do the opposite thing
  const toggleStopVideo = () => {
    logger().info(`${isVideoShown ? "Disabling" : "Enabling"} pip's video`);
    if (isVideoShown) {
      disableVideoTrack(localParticipant);
      dispatch(uiActions.stopPipVideo());
    } else {
      enableVideoTrack(localParticipant);
      dispatch(uiActions.resumePipVideo());
    }
  };

  const toggleMicrophoneAudio = () => {
    logger().info(
      `${!isMicrophoneMuted ? "Disabling" : "Enabling"} microphone audio`
    );
    if (!isMicrophoneMuted) {
      disableAudioTrack(localParticipant);
      dispatch(uiActions.muteMicrophone());
    } else {
      enableAudioTrack(localParticipant);
      dispatch(uiActions.unMuteMicrophone());
    }
  };

  const openLeaveCallModal = () => {
    logger().info(
      "UserAction: User clicked on the leave call button in the bottom bar"
    );
    dispatch(uiActions.setLeaveCallModal(true));

    // TODO add back these logger functions when we have the way to get user id defined
    // logger().info(`Tungsten User ${user.id} opened leave call modal`);
  };

  const adjustCanvasDimensions = () => {
    const element = getConsoleVideoElement();
    const canvas = getTelestrationCanvas();
    // console.log(
    //   "realigning canva's dimensions with element's dimensions"
    // );

    if (canvas && element) {
      // console.log("attempting realignment");
      canvas.width = element.clientWidth;
      canvas.height = element.clientHeight;
      canvas.style.left = element.getBoundingClientRect().left + "px";
      canvas.style.top = element.getBoundingClientRect().top + "px";
    }
    // When turning on drawMode, ensure canvas element is sized
    // appropriately for current Video element dimensions
    const newDimensions = getVideoDimensions();
    dispatch(telestrationActions.setVideoDimensions(newDimensions));
  };
  return (
    <NavBar
      ref={ref}
      className={clsx(styles.root, {
        [styles.drawerOpened]: sidePanelOpen,
        [styles.hidden]: hidden,
      })}
      data-test-id="bottombar"
    >
      <div className={styles.wrapper}>
        <div className={styles.leftSection}>
          {isHost && (
            <>
              {/* Sidebar should only be seen in a MP call */}
              {isMPCallMode && <SidebarButton />}

              {/* Telestration */}
              <Button
                variant="icon"
                endIcon={<Edit />}
                disabled={isConsoleVideoMuted}
                active={isDrawModeOn}
                tooltipProps={{
                  title: BeaconControlsToolTips.drawButton,
                  placement: "top",
                }}
                onClick={() => {
                  if (isDrawModeOn) {
                    dispatch(handleTurnOffDrawModeThunk());
                  } else {
                    if (isLaserPointerModeOn) {
                      dispatch(handleTurnOffLaserPointerModeThunk());
                    }
                    dispatch(telestrationActions.setIsDrawModeOn(true));
                    adjustCanvasDimensions();
                  }
                }}
                data-test-id="draw-button"
              />
              {/* Laser Pointer not implemented yet */}
              {
                <Button
                  variant="icon"
                  endIcon={<Laser />}
                  disabled={isConsoleVideoMuted}
                  active={isLaserPointerModeOn}
                  tooltipProps={{
                    title: "Remote Control",
                    placement: "top",
                  }}
                  onClick={() => {
                    if (isLaserPointerModeOn) {
                      dispatch(handleTurnOffLaserPointerModeThunk());
                    } else {
                      if (isDrawModeOn) {
                        dispatch(handleTurnOffDrawModeThunk());
                      }

                      dispatch(
                        telestrationActions.setIsLaserPointerModeOn(true)
                      );
                      adjustCanvasDimensions();
                    }
                    /** // TODO: dispatch(sidebarLaserClickAction) */
                  }}
                  data-test-id="laser-button"
                />
              }
            </>
          )}
        </div>
        <div className={styles.rightSection}>
          <Button
            variant="icon"
            endIcon={
              isMicrophoneMuted ? (
                <MicOff data-test-id="mic-off" />
              ) : (
                <MicNone data-test-id="mic-on" />
              )
            }
            tooltipProps={{
              title: isMicrophoneMuted ? "Unmute" : "Mute",
              placement: "top",
            }}
            onClick={toggleMicrophoneAudio}
            data-test-id="mic-button"
            disabled={!isAudioTrackPublished}
          />
          {isHostUser && (
            <Button
              variant="icon"
              endIcon={
                !isVideoShown ? (
                  <VideoCamOff data-test-id="cam-off" />
                ) : (
                  <VideoCam data-test-id="cam-on" />
                )
              }
              size="medium"
              tooltipProps={{
                title: isVideoShown ? "Stop Video" : "Start Video",
                placement: "top",
              }}
              onClick={toggleStopVideo}
              data-test-id="camera-button"
              disabled={disableVideoMute}
            />
          )}
          <Button
            variant="icon"
            endIcon={<CallEnd />}
            size="large"
            tooltipProps={{
              title: "Leave Event",
              placement: "top",
            }}
            theme="red"
            onClick={openLeaveCallModal}
            data-test-id="leave-call-button"
          />
        </div>
      </div>
    </NavBar>
  );
};
