import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AWS_KINESIS_WEBRTC } from "src/constants";
import { useGetFreezeFrames } from "src/domains/Beacon/hooks/PresenceCallSession/useGetFreezeFrames";
import { useGetSidebarStatus } from "src/domains/Beacon/hooks/PresenceCallSession/useGetSidebarStatus";
import { useGetTelestrations } from "src/domains/Beacon/hooks/PresenceCallSession/useGetTelestrations";
import { useAppSelector } from "src/domains/Beacon/store";
import { meetingActions } from "src/domains/Beacon/store/meeting";
import { selectMeetingState } from "src/domains/Beacon/store/meeting/selectors";
import { CallModes } from "src/domains/Beacon/store/meeting/types";
import { setConsoleFeaturesThunk } from "src/domains/Beacon/store/stream/thunks/setConsoleFeaturesThunk";
import { selectTwilioState } from "src/domains/Beacon/store/twilio/selectors";
import { selectCallStep } from "src/domains/Beacon/store/ui/selectors";
import { CallSteps } from "src/domains/Beacon/store/ui/types";
import { logger, LoggerLevels } from "src/logging/logger";
import {
  getCallSessionById,
  PresenceServerConsoleDetails,
} from "src/services/ApiClient/presence";

// This hook gets the Call Session Details in order to populate the Participant's Redux state.
// Every feature that has happened in the call should be loaded
// once the user has clicked the "Join" button in the PreCall Modal,
// so any participant that joins later, can have the latest info available.
// for example, freezeFrame, Sidebar status, noiseCancellation, etc.
export const useGetPresenceCallSession = () => {
  const [sessionDetails, setSessionDetails] = useState<
    PresenceServerConsoleDetails
  >();
  const [apiRetries, setApiRetries] = useState<number>(0);

  const { callSid, mode, hasFetchedFeaturesFromPresence } = useAppSelector(
    selectMeetingState
  );
  const { meetingToken } = useAppSelector(selectMeetingState);
  const mendaeraMeetingToken = meetingToken as any;
  let { consoleHasJoinedRoom } = useAppSelector(selectTwilioState);
  consoleHasJoinedRoom =
    mendaeraMeetingToken?.video_vendor === AWS_KINESIS_WEBRTC ||
    consoleHasJoinedRoom;

  const dispatch = useDispatch();
  const callStep = useAppSelector(selectCallStep);
  useGetSidebarStatus(sessionDetails);
  useGetFreezeFrames(sessionDetails);
  useGetTelestrations(sessionDetails);

  useEffect(() => {
    try {
      if (!consoleHasJoinedRoom) {
        if (apiRetries !== 0) {
          // reset retries count whenever the Console leaves the room to be ready in case of Console rejoin.
          setApiRetries(0);
        }
        // only make API call if there is a Console to get info for
        return;
      }
      const getCallSessionDetails = async () => {
        const data = await getCallSessionById(callSid);
        if (!data.content.features) {
          logger().logWithFields(
            LoggerLevels.info,
            {
              feature: "hooks/PresenceCallSession/useGetPresenceCallSession",
            },
            "sessionDetails.features does not exist. Retrying call to Presence Server for callSessionDetails " +
              JSON.stringify(sessionDetails)
          );
          // just increment the count by one, since `apiRetries` is in the useEffect deps below
          // this whole useEffect will run again and that will call the API again
          setApiRetries((prevApiRetries) => prevApiRetries + 1);
          return;
        }
        logger().logWithFields(
          LoggerLevels.info,
          {
            feature: "hooks/PresenceCallSession/useGetPresenceCallSession",
          },
          "Participant has fetched Console's session details successfully, must not fetch from Presence again."
        );
        // Must only fetch Console's session details once in a MP call
        dispatch(meetingActions.setHasFetchedFeaturesFromPresence(true));
        setSessionDetails(data.content);
        // thunk handles setting Console Features
        dispatch(setConsoleFeaturesThunk({ features: data.content?.features }));
      };

      // Presence Server details should only be fetched once the user has
      // joined the call, otherwise, if the Participant is in the PreCall Modal
      // and the Host clicks any Call Controls, it won't get the latests changes
      if (
        callSid &&
        callStep === CallSteps.IN_CALL &&
        mode === CallModes.MP && // Most fetch form Presence only in MP calls
        !hasFetchedFeaturesFromPresence // Most fetch from Presence only once
      ) {
        // we only want to retry calling this API 3 times if we are missing info for some reason
        if (apiRetries < 3) {
          logger().info(
            `Getting call session details from Presence, attempt number: ${
              apiRetries + 1
            }`
          );
          getCallSessionDetails();
        }
      }
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        {
          feature: "useGetPresenceCallSession",
        },
        "Error while getting call details from Presence"
      );
    }
  }, [
    callSid,
    callStep,
    apiRetries,
    mode,
    consoleHasJoinedRoom,
    hasFetchedFeaturesFromPresence,
  ]);
};
