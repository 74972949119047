import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";

import { HELP_CENTER_LINK, LEARNING_CENTER_LINK } from "src/constants";
import { locations } from "src/utils/locations";

import Can from "avail-web-application/Common/Acl/AccessControl";
import { ReactComponent as EventsBetaIcon } from "avail-web-application/Common/Assets/Icons/mp-event-beta.svg";
import { roles } from "avail-web-application/Common/Constants/userTypes";
import "avail-web-application/Common/Styles/fonts.scss";
import { getUserOrganisationAction } from "avail-web-application/Features/Home/Features/Users/UsersActions";

import { getCurrentUser } from "../../../selectors/homeReducer";
import {
  getOrganisation,
  getOrganisationBrand,
} from "../../../selectors/usersReducer";
import styles from "./SideMenu.scss";
import { SideMenuItem } from "./SideMenuItem";

interface ISideMenuProps {
  t: any; // prop comming from withTranslation HOC
}

const SideMenu = ({ t }: ISideMenuProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const organisation = useSelector(getOrganisation);
  const brand = useSelector(getOrganisationBrand);

  useEffect(() => {
    if (
      currentUser?.userTypes[0] !== (roles.individualSalesRep && roles.guest)
    ) {
      dispatch(getUserOrganisationAction(currentUser.id));
    }
  }, []);

  return (
    <div className={styles.sidemenu}>
      <Can userTypes={currentUser?.userTypes} action="read" context="home">
        <SideMenuItem
          title="Home"
          to="/home"
          iconName="icon-pie-chart-outline"
        />
      </Can>
      <Can userTypes={currentUser?.userTypes} action="read" context="scheduler">
        <SideMenuItem
          title="Scheduler"
          to="/scheduler"
          iconName="icon-calendar-outline"
        />
      </Can>
      <Can userTypes={currentUser?.userTypes} action="read" context="users">
        <SideMenuItem
          title="Users"
          to="/users"
          iconName="icon-person-outline"
        />
      </Can>
      <Can userTypes={currentUser?.userTypes} action="read" context="vendors">
        <SideMenuItem
          title="Business Unit"
          to={locations.vendors}
          iconName="icon-briefcase-outline"
        />
      </Can>
      <Can
        userTypes={currentUser?.userTypes}
        action="read"
        context="businessUnitProfile"
      >
        <SideMenuItem
          title="Vendor Profile"
          to={`/vendors/profile?brId=${brand?.id}&buId=${organisation?.id}`}
          iconName="icon-briefcase-outline"
        />
      </Can>
      <Can userTypes={currentUser?.userTypes} action="read" context="hospitals">
        <SideMenuItem
          title={t("features.org.hospital.name_plural")}
          to={locations.hospitals}
          iconName="icon-plus-square-outline"
        />
      </Can>
      <Can
        userTypes={currentUser?.userTypes}
        action="read"
        context="hospitalProfile"
      >
        <SideMenuItem
          title="Facility Profile"
          to={`/hospitals/profile?hospitalId=${organisation?.id}`}
          iconName="icon-plus-square-outline"
        />
      </Can>
      <Can userTypes={currentUser?.userTypes} action="read" context="vendors">
        <SideMenuItem
          title="Vendors"
          to={locations.brands}
          iconName="icon-award-outline"
        />
      </Can>
      <Can userTypes={currentUser?.userTypes} action="read" context="consoles">
        <SideMenuItem
          title="Consoles"
          to={locations.consoles}
          iconName="icon-hard-drive-outline"
        />
      </Can>
      <Can
        userTypes={currentUser?.userTypes}
        action="read"
        context="hospitalApprovalList"
      >
        <SideMenuItem
          title="Approval List"
          to={`/hospitalApprovalList/${organisation?.id}`}
          iconName="icon-checkmark-circle-2-outline"
        />
      </Can>
      <Can
        userTypes={currentUser?.userTypes}
        action="read"
        context="hospitalNetwork"
      >
        <SideMenuItem
          title={t("features.hospital_network.name_plural")}
          to={locations.hospitalNetwork}
          iconName="icon-layers-outline"
        />
      </Can>
      <Can userTypes={currentUser?.userTypes} action="read" context="provider">
        <SideMenuItem
          title={t("features.provider.name_plural")}
          to={locations.provider}
          iconName="icon-npm-outline"
        />
      </Can>
      <Can userTypes={currentUser?.userTypes} action="read" context="cdr">
        <SideMenuItem
          title="CDR"
          to={locations.cdr}
          iconName="icon-list-outline"
        />
      </Can>
      <Can userTypes={currentUser?.userTypes} action="read" context="events">
        <SideMenuItem
          title="Events"
          to={locations.events}
          iconOverride={<EventsBetaIcon />}
        />
      </Can>

      {/* <SideMenuItem
        title="Telepresence"
        to={locations.tpdemo}
        iconOverride={<QueuePlayNextIcon />}
        asIconButton
      /> */}

      <div className={styles.spacer} />
      <SideMenuItem
        title="Learning Center"
        // marketing page that has a bunch of training info for customers
        to={LEARNING_CENTER_LINK}
        iconName="icon-book-open-outline"
        openInNewTab={true}
        asIconButton
      />
      <SideMenuItem
        title="Help"
        // we go to an marketing page which has links to the help-center or a new training center
        // help-center lives in our app (check the route), training and other links live outside of our app
        to={HELP_CENTER_LINK}
        iconName="icon-question-mark-circle-outline"
        openInNewTab={true}
        asIconButton
      />
    </div>
  );
};
const SideMenuWTranslation = withTranslation()(SideMenu);
export { SideMenuWTranslation as SideMenu };
