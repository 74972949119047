import { RemoteVideoTrack } from "twilio-video";

import { useEffect, MutableRefObject } from "react";
import { useDispatch } from "react-redux";

import clsx from "clsx";

import { consoleVideoTrackNotStartedThunk } from "src/domains/Beacon/store/meeting/thunks/consoleVideoTrackNotStartedThunk";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { redrawDueResizingThunk } from "src/domains/Beacon/store/telestration/thunks/redrawDueResizingThunk";
import { logger } from "src/logging/logger";

import styles from "./styles.scss";

interface IProps {
  track: RemoteVideoTrack;
  id?: string;
  videoRef: MutableRefObject<HTMLVideoElement>;
  consoleHasJoinedRoom: boolean;
  "data-test-id"?: string;
  showVideo: boolean;
}

export const Video = ({
  track,
  id,
  videoRef,
  consoleHasJoinedRoom,
  "data-test-id": dataTestId,
  showVideo,
}: IProps) => {
  // Detects when Twilio receives a new track (must check the unique name),
  // if track was switched off or if its enable state changed
  const dispatch = useDispatch();

  useEffect(() => {
    const playTrack = () => {
      try {
        videoRef.current.play();
      } catch (error) {
        logger().error(`Error when playing track: ${error.message}`);
      }
    };

    if (videoRef.current && track && consoleHasJoinedRoom) {
      /**
       * Listening to the video element resize in order to redraw Telestration.
       * Using the ResizeObserver API instead of useEventListener since using
       * videoDimensions as a dependency is unreliable for real-time
       * resize events.
       * https:// developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
       */

      const videoResizeObserver = new ResizeObserver(() => {
        logger().info(`Resize event detected for video#${id} detected`);
        dispatch(telestrationActions.setIsResizing(true));
        dispatch(redrawDueResizingThunk());
        dispatch(telestrationActions.setIsResizing(false));
      });

      videoResizeObserver.observe(videoRef.current);

      // attach track to video element
      track.attach(videoRef.current);
      track.once("started", () => {
        logger().info(`video#${id} video track has started`);
        // .play() only after the track has started
        playTrack();
      });

      if (track.isStarted === false) {
        /**
         * If we get here, it means that Console has joined the room and we are
         * subscribed to a track that hasn't started yet.
         * consoleVideoTrackNotStartedThunk will wait a given amount of time
         * to allow the track to start on its own before refreshing the call
         */
        dispatch(consoleVideoTrackNotStartedThunk({ videoId: id }));
      }
    }
  }, [
    videoRef.current,
    track?.name,
    track?.isEnabled,
    track?.isStarted,
    consoleHasJoinedRoom,
  ]);

  return (
    <>
      <video
        ref={videoRef}
        id={id || null}
        data-test-id={dataTestId}
        className={clsx(styles.root, { [styles.hidden]: !showVideo })}
        /**
         * Explicitly setting autoPlay to false even though it's the default.
         * autoPlay is blocked on some browsers
         */
        autoPlay={false}
        /**
         * muted prop specifies that the audio output of the video should be muted.
         * Some browsers (Chrome) won't start a video without explicit action when muted is false.
         * */
        muted={true}
      />
    </>
  );
};
