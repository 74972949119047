import { AVKTelestrationClearEvent } from "availkit-js/dist/Models/Events/AVKTelestrationClearEvent";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "src/domains/Beacon/store";
import { telestrationActions } from "src/domains/Beacon/store/telestration";
import { initialState } from "src/domains/Beacon/store/telestration";
import { clearLocalAnnotationsThunk } from "src/domains/Beacon/store/telestration/thunks/clearLocalAnnotationsThunk";
import { logger, LoggerLevels } from "src/logging/logger";
import { AvailKitService } from "src/services/AvailKitService";

// does all the actions necessary to turn off draw mode
export const handleTurnOffLaserPointerModeThunk = createAsyncThunk<
  void,
  // First argument to the payload creator
  void,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "telestration/handleTurnOffLaserPointerModeThunk",
  (_arg, { getState, dispatch }) => {
    try {
      logger().info("Turning off Laser Pointer Mode");
      const state = getState();
      dispatch(telestrationActions.setHighlightPoints([]));
      dispatch(telestrationActions.setIsLaserPointerModeOn(false));
    } catch (error: any) {
      logger().logWithFields(
        LoggerLevels.error,
        { feature: "handleTurnOffDrawModeThunk" },
        "Error turning off telestration",
        error?.message
      );
      throw new Error("Clearing telestration failed");
    }
  }
);
