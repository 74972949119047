// import AWS object without services
import * as KVSWebRTC from "amazon-kinesis-video-streams-webrtc";
import * as AWS from "aws-sdk";
import { Guid } from "guid-typescript";

import { logger } from "src/logging/logger";

import DomainConstants from "avail-web-ui/domains/DomainConstants";

// AWS Credentials - Typically, this needs to be retrieved from Incoming Call.

const region = "us-west-2";
/* Make sure, this is First Name, Last Name */
const clientId = "Mendaera Remote Viewer";
const remoteViewer = {} as any;
export class KinesisVideoStreamViewer {
  meetingToken: any;

  constructor(meetingToken) {
    this.meetingToken = meetingToken;
  }

  stopConsoleViewerSession = () => {
    try {
      console.log("[VIEWER] Stopping viewer connection");

      if (remoteViewer.signalingClient) {
        remoteViewer.signalingClient.close();
        remoteViewer.signalingClient = null;
      }

      if (remoteViewer.peerConnection) {
        remoteViewer.peerConnection.close();
        remoteViewer.peerConnection = null;
      }

      if (remoteViewer.localStream) {
        remoteViewer.localStream.getTracks().forEach((track) => track.stop());
        remoteViewer.localStream = null;
      }

      if (remoteViewer.remoteStream) {
        remoteViewer.remoteStream.getTracks().forEach((track) => track.stop());
        remoteViewer.remoteStream = null;
      }

      if (remoteViewer.peerConnectionStatsInterval) {
        clearInterval(remoteViewer.peerConnectionStatsInterval);
        remoteViewer.peerConnectionStatsInterval = null;
      }

      if (remoteViewer.localView) {
        remoteViewer.localView.srcObject = null;
      }

      if (remoteViewer.remoteView) {
        remoteViewer.remoteView.srcObject = null;
      }

      if (remoteViewer.dataChannel) {
        remoteViewer.dataChannel = null;
      }
    } catch (e) {
      console.error("[VIEWER] Encountered error stopping", e);
    }
  };

  startConsoleViewerSession = async () => {
    const accessKeyId = this.meetingToken.aws_access_key_id;
    const secretAccessKey = this.meetingToken.aws_secret_access_key;
    const sessionToken = this.meetingToken.aws_session_token;

    const kinesisVideoClient = new AWS.KinesisVideo({
      region,
      accessKeyId,
      secretAccessKey,
      sessionToken,
      endpoint: null,
      correctClockSkew: true,
    });
    logger().info(accessKeyId, secretAccessKey, sessionToken);
    logger().info("Step 1: KVC client created");

    const channelName = "demo-mike-test";
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ["WSS", "HTTPS"],
          Role: KVSWebRTC.Role.VIEWER,
        },
      })
      .promise();
    const endpointsByProtocol: any = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
      (endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
      },
      {}
    );
    logger().info("[VIEWER] Endpoints:", endpointsByProtocol);

    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels(
      {
        region,
        accessKeyId,
        secretAccessKey,
        sessionToken,
        endpoint: endpointsByProtocol.HTTPS,
        correctClockSkew: true,
      }
    );
    const iceServers = [];
    iceServers.push({
      urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443`,
    });
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      })
    );

    // Create Signaling Client
    remoteViewer.signalingClient = new KVSWebRTC.SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      // Having spaces in name results in an issue
      clientId: "Mendaera-Remote-Viewer" + Guid.create().toString(),
      role: KVSWebRTC.Role.VIEWER,
      region,
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });
    const resolution = {
      width: { ideal: 1280 },
      height: { ideal: 720 },
    };
    const constraints = {
      video: resolution,
      audio: true,
    };
    const configuration: any = {
      iceServers,
      iceTransportPolicy: "all",
    };

    remoteViewer.peerConnection = new RTCPeerConnection(configuration);
    remoteViewer.signalingClient.on("open", async () => {
      logger().info("[VIEWER] Connected to signaling service");

      // Get a stream from the webcam, add it to the peer connection, and display it in the local view.
      // If no video/audio needed, no need to request for the sources.
      // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
      // Create an SDP offer to send to the master
      logger().info("[VIEWER] Creating SDP offer");
      await remoteViewer.peerConnection.setLocalDescription(
        await remoteViewer.peerConnection.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      // if (formValues.useTrickleICE) {
      remoteViewer.signalingClient.sendSdpOffer(
        remoteViewer.peerConnection.localDescription
      );
      // }
      logger().info("[VIEWER] Generating ICE candidates");
    });
    remoteViewer.signalingClient.on("sdpAnswer", async (answer) => {
      // Add the SDP answer to the peer connection
      logger().info("[VIEWER] Received SDP answer");
      logger().info("SDP answer:", answer);

      await remoteViewer.peerConnection.setRemoteDescription(answer);
    });

    remoteViewer.signalingClient.on("iceCandidate", (candidate) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      logger().info("[VIEWER] Received ICE candidate");
      logger().debug("ICE candidate", candidate);
      // if (shouldAcceptCandidate(formValues, candidate)) {
      remoteViewer.peerConnection.addIceCandidate(candidate);
      // } else {
      //   console.log("[VIEWER] Not adding candidate from peer.");
      // }
    });

    remoteViewer.signalingClient.on("close", () => {
      logger().info("[VIEWER] Disconnected from signaling channel");
    });

    remoteViewer.signalingClient.on("error", (error) => {
      logger().error("[VIEWER] Signaling client error:", error);
    });

    // Send any ICE candidates to the other peer
    remoteViewer.peerConnection.addEventListener(
      "icecandidate",
      ({ candidate }) => {
        if (candidate) {
          logger().info("[VIEWER] Generated ICE candidate");
          logger().debug("ICE candidate:", candidate);

          // When trickle ICE is enabled, send the ICE candidates as they are generated.
          // if (formValues.useTrickleICE) {
          //   if (shouldSendIceCandidate(formValues, candidate)) {
          //     console.log("[VIEWER] Sending ICE candidate");
          remoteViewer.signalingClient.sendIceCandidate(candidate);
          // } else {
          //   console.log("[VIEWER] Not sending ICE candidate");
          // }
          // }
        } else {
          // TODO: Ensure SDP offer is made only once.
          // logger().info("[VIEWER] All ICE candidates have been generated");
          // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
          // if (!formValues.useTrickleICE) {
          // console.log("[VIEWER] Sending SDP offer");
          //   console.debug("SDP offer:", viewer.peerConnection.localDescription);
          // viewer.signalingClient.sendSdpOffer(
          // viewer.peerConnection.localDescription
          // );
          // }
        }
      }
    );

    remoteViewer.peerConnection.addEventListener(
      "connectionstatechange",
      async (event) => {
        // printPeerConnectionStateInfo(event, "[VIEWER]");
      }
    );

    // As remote tracks are received, add them to the remote view
    remoteViewer.peerConnection.addEventListener("track", (event) => {
      const remoteView = document.getElementsByTagName("video")[0];
      if (!remoteView || remoteView.srcObject) {
        console.error("No Remote View found");
        return;
      }
      remoteViewer.remoteStream = event.streams[0];
      remoteView.srcObject = remoteViewer.remoteStream;
    });

    logger().info("[VIEWER] Starting viewer connection");
    remoteViewer.signalingClient.open();
  };
}
