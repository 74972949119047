// Object that stores our different paths/locations so if we need to send the user somewhere
// we can just use this object rather than hardcode the pathname
export const locations = {
  accountCreation: "/accountCreation",
  accountSetup: "/accountSetup",
  addNewUser: "/addNewUser",
  cdr: "/cdr",
  billing: "/billing",
  brands: "/brands",
  invoices: "/invoices",
  consoleProfile: "/consoles/:id/profile",
  consoles: "/consoles",
  createNewEvent: "/events/create/new",
  createNewDepartment: "/hospitals/:id/createNewDepartment",
  createNewHospital: "/hospitals/createNewHospital",
  createNewRoom: "/departments/:id/createNewRoom",
  createNewVendor: "/vendors/createNewVendor",
  createUser: "/users/create",
  createUserForm: "/users/create/form",
  departmentProfile: "/hospitals/departments/profile",
  distributors: "/distributors",
  hospitalApprovalList: "/hospitalApprovalList/:id",
  hospitalNetwork: "/hospitalNetwork",
  hospitalProfile: "/hospitals/profile/",
  hospitals: "/hospitals",
  login: "/login",
  eventLogin: "/event-login",
  onboarding: "/onboarding",
  provider: "/provider",
  providerProfile: "/provider/profile/",
  roomProfile: "/hospitals/rooms/profile",
  userProfile: "/users/profile/",
  users: "users",
  usersProfileGeneral: "users/profile/general",
  usersProfileHospitals: "users/profile/hospitals",
  usersProfileOrganisation: "users/profile/organisation",
  usersProfileTherapies: "users/profile/therapies",
  vendorApprovalList: "/vendorApprovalList/:id",
  vendorProfile: "/vendors/profile",
  vendors: "/vendors",
  event: "/event",
  editEvent: "/events/:eventId",
  events: "/events",
  tpdemo: "/tpdemo",
  helpCenter: "/help-center",
  usage: "/trial-usage",
};
