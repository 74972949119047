import React from "react";

import { CONSOLE_VIDEO_ELEMENT_ID } from "src/domains/Beacon/constants";

import styles from "./styles.scss";

interface TelepresenceVideoContainerOwnProps {
  meetingToken: any;
}
type TelepresenceVideoContainerProps = TelepresenceVideoContainerOwnProps;

export class TelepresenceVideoContainer extends React.Component {
  render() {
    return (
      <div className={styles.telepresenceVideoContainer}>
        <video
          id={CONSOLE_VIDEO_ELEMENT_ID}
          className={styles.mendaeraConsoleView}
          autoPlay={true}
          playsInline={true}
          controls={true}
          muted={true}
        />
      </div>
    );
  }
}
